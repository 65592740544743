var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":!_vm.openMessages,"rounded":"sm"}},[_c('div',{staticClass:"invoice-register-table pt-1",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"custom-search d-flex",staticStyle:{"width":"223px","float":"right"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('div',{staticClass:"float-left ir-btn-wrapper",staticStyle:{"width":"460px !important"}},[_c('b-button',{staticStyle:{"margin-right":"5px"},attrs:{"variant":"primary","disabled":_vm.markread},on:{"click":_vm.markAsRead}},[(_vm.markread)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Marking... ")],1):_c('span',[_vm._v("Mark as read")])]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.markallread},on:{"click":_vm.markAllAsRead}},[(_vm.markallread)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Marking... ")],1):_c('span',[_vm._v("Mark all as read")])])],1),_c('br'),_c('br'),(_vm.openMessages)?_c('vue-good-table',{ref:"messagesTable",staticClass:"mt-2",attrs:{"columns":_vm.columns,"rows":_vm.openMessages,"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"search-options":{
          enabled: false,
          externalQuery: _vm.searchTerm },"pagination-options":{
          enabled: true,
          perPage:_vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{attrs:{"to":("/messages/" + (props.row.id))}},[_vm._v(_vm._s(props.row.id))])],1):(props.column.field === 'user_email')?_c('span',[_c('router-link',{attrs:{"to":("/user/details/" + (props.row.user_id))}},[_vm._v(_vm._s(props.row.user_email))])],1):(props.column.field === 'msg_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.msg_status)}},[_vm._v(" "+_vm._s(_vm.messageStatus(props.row))+" ")])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap mt-1"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10','25','50']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,1877131005)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card>
    <b-overlay
      :show="!openMessages"
      rounded="sm"
    >
      <div
        class="invoice-register-table pt-1"
        style="text-align:left"
      >
        <!-- search input -->
        <div
          class="custom-search d-flex"
          style="width:223px;float:right;"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <div
          class="float-left ir-btn-wrapper"
          style="width: 460px !important;"
        >
          <b-button
            variant="primary"
            :disabled="markread"
            style="margin-right: 5px;"
            @click="markAsRead"
          >
            <span v-if="markread">
              <b-spinner
                small
                type="grow"
              />
              Marking...
            </span>
            <span v-else>Mark as read</span>
          </b-button>
          <b-button
            variant="primary"
            :disabled="markallread"
            @click="markAllAsRead"
          >
            <span v-if="markallread">
              <b-spinner
                small
                type="grow"
              />
              Marking...
            </span>
            <span v-else>Mark all as read</span>
          </b-button>
        </div>
        <br><br>
        <!-- table -->
        <vue-good-table
          v-if="openMessages"
          ref="messagesTable"
          :columns="columns"
          :rows="openMessages"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <router-link :to="`/messages/${props.row.id}`">{{ props.row.id }}</router-link>
            </span>

            <!-- Column: Email -->
            <span v-else-if="props.column.field === 'user_email'">
              <router-link :to="`/user/details/${props.row.user_id}`">{{ props.row.user_email }}</router-link>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'msg_status'">
              <b-badge :variant="statusVariant(props.row.msg_status)">
                {{ messageStatus(props.row) }}
              </b-badge>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>

  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal, BForm, BFormGroup, BRow, BCol, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
  import axios from '@/libs/axios' // eslint-disable-line

import 'vue2-datepicker/index.css'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BBadge,
    BOverlay,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BSpinner,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      item: [],
      rows: [],
      searchTerm: '',
      openMessages: null,
      markread: false,
      markallread: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    columns() {
      const columns = [
        {
          label: 'Message #',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Date',
          field: 'created_at',
        },
        {
          label: 'Subject',
          field: 'msg_subject',
        },
      ]
      if (this.user.userRole === 'Admin') {
        columns.push(
          {
            label: 'Domain',
            field: 'user_domain',
          },
          {
            label: 'Email',
            field: 'user_email',
          },
        )
      }
      columns.push({
        label: 'Status',
        field: 'msg_status',
      })
      return columns
    },
    statusVariant() {
      const statusColor = {
        1: 'secondary',
        0: 'primary',
      }

      return status => statusColor[status]
    },
    users() {
      return this.$store.state.users.expiredUsers
    },
    invoiceData() {
      return { merged: [] }
    },
  },
  created() {
    this.$store.dispatch('messages/fetchMessages').then(() => {
      const { messages } = this.$store.state.messages
      this.openMessages = this.sortUnreadMessages(messages)
    })
  },
  methods: {
    messageStatus(message) {
      let status = ''
      if (message.msg_status === 1) status = 'Read'
      else if (message.msg_status === 0) status = 'Unread'
      return status
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    cFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    sortUnreadMessages(messages) {
      const adminReadMessages = []
      const adminUnreadMessages = []
      const userMessages = []

      for (const message of messages) { // eslint-disable-line
        const destination = Number(message.destination)
        const status = Number(message.msg_status)
        if ((destination === 1001 || destination === 1002) && status === 0) {
          adminUnreadMessages.push(message)
        } else if ((destination === 1001 || destination === 1002) && status === 1) {
          adminReadMessages.push(message)
        } else {
          userMessages.push(message)
        }
      }
      const final_messages1 = adminUnreadMessages.concat(adminReadMessages)
      const final_messages2 = final_messages1.concat(userMessages)
      return final_messages2
    },
    async markAsRead() {
      this.markread = true
      const rows = this.$refs.messagesTable.selectedPageRows
      if (rows.length === 0) {
        this.notify({
          text: 'Please select a message.',
          variant: 'danger',
        })
          return // eslint-disable-line
      }

      const rowCount = rows.length
      const payloadArray = []
      for (let i = 0; i < rowCount; i += 1) {
        const item = {
          id: rows[i].id,
        }
        payloadArray.push(item)
      }
      const payload = {
        ids: JSON.stringify(payloadArray),
      }
      const url = '/api/messages/mark_read'
      await axios.post(url, payload).then(res => { // eslint-disable-line
        this.notify({
          text: 'Success!',
          variant: 'success',
        })
      })
      this.markread = false
      this.fetchMessages()
    },

    async markAllAsRead() {
      this.markallread = true
      const url = '/api/messages/mark_all_read'
      await axios.post(url).then(res => { // eslint-disable-line
        this.notify({
          text: 'Success!',
          variant: 'success',
        })
      })
      this.markallread = false
      this.fetchMessages()
    },

    fetchMessages() {
      this.$store.dispatch('messages/fetchMessages').then(() => {
        this.openMessages = this.$store.state.messages.messages
        this.$forceUpdate()
        this.fetchNewMessages()
      })
    },

    fetchNewMessages() {
      this.$store.dispatch('messages/fetchNewMessages')
    },
  },
}
</script>

  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  .mx-datepicker {
    width: 100% !important;
  }

  .invoice-register-table {
    thead tr{
      background: #f3f2f7;
    }

    th, td {
      font-size: 14px;
      text-align: center !important;
      vertical-align: middle !important;
    }

    td {
      height: 62px;
    }
  }

  .ir-btn-wrapper {
    width: 30rem;

    .ir-btn {
      width: 100%;
      max-width: 148px;
    }
  }

  .activation-btn {
    width: 100%;
    max-width: 16rem;
  }
  </style>
